import React, { useState } from 'react'

import { GiftCardIcon } from '@toasttab/buffet-pui-icons'

import { BirthdayBoxFooter } from './BirthdayBoxFooter'
import { CompleteProfileBoxFooter } from './CompleteProfileBoxFooter'
import { WaysToItem } from './WaysToItem'
import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { numberFormatCurrency } from '../../utils/functions'

export const WaysToEarn = () => {
  const {
    rewardsConfig: {
      accrualRate,
      birthdayRewardsEnabled,
      dayTimeBonusConfigs = [],
      diningAccrualBonusConfigs = [],
      accrualType,
      rewardsVisitConfig
    },
    openUserDetailsModal,
    userDetailsModal,
    isOOAvailable,
    isOOBirthdayRewardsAvailable,
    restaurant
  } = useLoyaltyContext()

  const accrualValue = numberFormatCurrency(accrualRate)
  const minSpendForVisitAccrual = rewardsVisitConfig?.minimumSpendForAccrual

  const hasBirthdaySubmitted =
    userDetailsModal.birthdayMonthSubmitted &&
    userDetailsModal.birthdayDaySubmitted

  const shortUrl = restaurant?.shortUrl

  const showOOLink = isOOAvailable && isOOBirthdayRewardsAvailable && shortUrl

  const isVisitAccrual = accrualType === 'VISIT'
  const title = isVisitAccrual ? 'Earn for each visit' : '1 point'

  const [subtitle] = useState(() => {
    if (isVisitAccrual) {
      return minSpendForVisitAccrual
        ? `Any order you place with us of $${minSpendForVisitAccrual} or more`
        : 'Any order you place with us.'
    }
    return `for $${accrualValue} spent`
  })

  const [doublePointsTitle] = useState(() => {
    return isVisitAccrual ? 'Double visits' : 'Double points'
  })
  const [triplePointsTitle] = useState(() => {
    return isVisitAccrual ? 'Triple visits' : 'Triple points'
  })

  return (
    <>
      <div className='p-6 pb-8'>
        <p className='type-large text-default font-bold pb-2'>Ways to earn</p>
        <p className='type-default text-secondary'>
          You earn automatically every time you use a linked payment card.
        </p>
        <WaysToItem
          icon={<p className='type-headline-5'>1</p>}
          title={title}
          subtitles={[subtitle]}
        />
        {dayTimeBonusConfigs.map(
          (
            {
              enabled,
              multiplier,
              daysAvailableBits,
              daysAvailable,
              startTime,
              endTime
            },
            i
          ) => {
            return (
              enabled && (
                <WaysToItem
                  key={`day-time-bonus-${i}`}
                  icon={
                    <p className='type-headline-5'>
                      {multiplier === 2 ? '2x' : '3x'}
                    </p>
                  }
                  title={
                    multiplier === 2 ? doublePointsTitle : triplePointsTitle
                  }
                  subtitles={[
                    getDays(daysAvailableBits || daysAvailable),
                    getTime(startTime, endTime)
                  ]}
                />
              )
            )
          }
        )}
        {diningAccrualBonusConfigs.map(
          (
            { enabled, multiplier, behaviourMode, orderSource, scheduled },
            i
          ) => {
            return (
              enabled && (
                <WaysToItem
                  key={`dining-option-${i}`}
                  icon={
                    <p className='type-headline-5'>
                      {multiplier === 2 ? '2x' : '3x'}
                    </p>
                  }
                  title={
                    multiplier === 2 ? doublePointsTitle : triplePointsTitle
                  }
                  subtitles={[
                    getGuestDiningOptionBonusDescription(
                      orderSource,
                      behaviourMode,
                      scheduled
                    )
                  ]}
                />
              )
            )
          }
        )}
        {birthdayRewardsEnabled && hasBirthdaySubmitted && (
          <WaysToItem
            icon={
              <GiftCardIcon
                accessibility='decorative'
                aria-label='birthday treat icon'
              />
            }
            title='Birthday Treat'
            subtitles={[
              <span data-testid='ways-to-earn-birthday'>
                We'll notify you when your surprise is waiting in-store
                {showOOLink && (
                  <>
                    {' '}
                    or{' '}
                    <a
                      id='lw-click-ways-to-redeem-oo'
                      href={`/${shortUrl}/v3?utmSource=loyalty-web`}
                      rel='noopener noreferrer'
                      target='_blank'
                      className='text-default font-bold no-underline'
                    >
                      online!
                    </a>
                  </>
                )}
              </span>
            ]}
          />
        )}
      </div>
      {birthdayRewardsEnabled && !hasBirthdaySubmitted ? (
        <BirthdayBoxFooter onClick={openUserDetailsModal} />
      ) : (
        <CompleteProfileBoxFooter />
      )}
    </>
  )
}

export const getDays = (daysAvailableBits = 0) => {
  const binaryString = daysAvailableBits.toString(2).split('').reverse()

  const days = [
    binaryString[1] === '1' && 'Mon',
    binaryString[2] === '1' && 'Tue',
    binaryString[3] === '1' && 'Wed',
    binaryString[4] === '1' && 'Thu',
    binaryString[5] === '1' && 'Fri',
    binaryString[6] === '1' && 'Sat',
    binaryString[0] === '1' && 'Sun'
  ].filter((d) => d)

  if (days.length === 7) {
    return 'Every day'
  }
  if (days.length <= 2) {
    return days.map(getLongWeekName).join(', ')
  }
  return days.join(', ')
}

const getLongWeekName = (day) => {
  switch (day) {
    case 'Mon':
      return 'Monday'
    case 'Tue':
      return 'Tuesday'
    case 'Wed':
      return 'Wednesday'
    case 'Thu':
      return 'Thursday'
    case 'Fri':
      return 'Friday'
    case 'Sat':
      return 'Saturday'
    case 'Sun':
    default:
      return 'Sunday'
  }
}

const getTime = (startTime, endTime) => {
  if (startTime && endTime) {
    const from = displayTime(startTime)
    const to = displayTime(endTime)
    return `${from} to ${to}`
  }
  return ''
}

const displayTime = (timestamp) => {
  const startArray = timestamp.substring(13).split(':')
  const hours = startArray[0]
  const minutes = startArray[1]
  const meridiem = startArray[2].substring(3)
  return `${hours}:${minutes}${meridiem.toLowerCase()}`
}

const getGuestDiningOptionBonusDescription = (
  orderSource,
  behaviourMode,
  scheduled
) => {
  const orderSourceLabel = ORDER_SOURCE_OPTIONS.find(
    (o) => o.value === orderSource
  ).label
  const behaviourLabel = BEHAVIOUR_OPTIONS.find(
    (o) => o.value === behaviourMode
  ).label

  const orderSourceFormat =
    orderSource === POS_TOAST_GO || orderSource === TOAST_APP
      ? orderSourceLabel
      : orderSourceLabel.toLowerCase()

  return `for ${scheduled ? 'scheduled ' : ''}${
    behaviourMode !== ANY ? behaviourLabel.toLowerCase() + ' ' : ''
  }orders${orderSource !== ANY ? ' from the ' + orderSourceFormat : ''}`
}

const ONLINE_ORDERING = 'ONLINE_ORDERING',
  TOAST_APP = 'TOAST_APP',
  KIOSK = 'KIOSK',
  POS_TOAST_GO = 'POS_TOAST_GO',
  ANY = 'ANY',
  TAKE_OUT = 'TAKE_OUT',
  DINE_IN = 'DINE_IN',
  DELIVERY = 'DELIVERY'

const BEHAVIOUR_OPTIONS = [
  {
    value: TAKE_OUT,
    label: 'Take out',
    disabled: false
  },
  {
    value: DINE_IN,
    label: 'Dine in',
    disabled: false
  },
  {
    value: DELIVERY,
    label: 'Delivery',
    disabled: false
  },
  {
    value: ANY,
    label: 'Any',
    disabled: false
  }
]

export const ORDER_SOURCE_OPTIONS = [
  {
    value: ONLINE_ORDERING,
    label: 'Online ordering website',
    disabled: false
  },
  {
    value: TOAST_APP,
    label: 'Local by Toast app',
    disabled: false
  },
  {
    value: KIOSK,
    label: 'Kiosk',
    disabled: false
  },
  {
    value: POS_TOAST_GO,
    label: 'POS/Toast Go',
    disabled: false
  },
  {
    value: ANY,
    label: 'Any',
    disabled: false
  }
]
