import React from 'react'

import { PlaceSettingIcon } from '@toasttab/buffet-pui-icons'

import { useLoyaltyContext } from '../../context/LoyaltyContext'

const logoStyle =
  'block w-18 h-18 mx-auto border border-white rounded-lg shadow'

export const Header = () => {
  const { restaurant, rewardsConfig } = useLoyaltyContext()

  const image = restaurant?.thumbnailImage360Url
  const programName = rewardsConfig?.programName
  const restaurantName = restaurant?.restaurantName

  return (
    <div className='px-6 pb-4'>
      <div className='max-w-sm mx-auto'>
        <div className='inline-block w-full mb-2 -mt-4'>
          {image ? (
            <img
              className={`${logoStyle} bg-white`}
              src={image}
              alt={`${restaurantName} logo`}
            />
          ) : (
            <div
              className={`${logoStyle} bg-primary-25 flex items-center justify-center`}
            >
              <PlaceSettingIcon
                accessibility='decorative'
                aria-label='logo placeholder'
                className='text-primary-100 fill-current'
              />
            </div>
          )}
        </div>
        <p className='type-large text-default text-center font-bold'>
          {programName ? programName : restaurantName}
        </p>
      </div>
    </div>
  )
}
