import { useFlags } from '@toasttab/banquet-feature-flags'

import { LoyaltyView } from './LoyaltyView'
import { LoyaltyContextProvider } from '../../context/LoyaltyContext'
import { LoyaltyPageProvider } from '../../context/LoyaltyPageContext'
import { LoyaltyPageV2 } from '../LoyaltyPageV2/LoyaltyPage'

export const LoyaltyPage = () => {
  const { loyTrackingPageV2 } = useFlags()

  if (loyTrackingPageV2 && false) {
    return (
      <LoyaltyPageProvider>
        <LoyaltyPageV2 />
      </LoyaltyPageProvider>
    )
  }

  return (
    <LoyaltyContextProvider>
      <LoyaltyView />
    </LoyaltyContextProvider>
  )
}
